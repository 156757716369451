import {makeStyles, Theme} from '@material-ui/core';
import {Title, colors} from '@valmet-iop/ui-common';
import * as React from 'react';
import {QueryStatus} from 'react-query';
import {scrollBarStyle} from 'utils/styles';
import {useRedirect} from '../context/redirect-context';
import {Panel} from '../Panel';
const {grey12} = colors;
export interface IItemEditProps {
  children: React.ReactNode;
  status?: QueryStatus;
  title: string;
}

export function ItemEditLayout({children, status, title}: IItemEditProps) {
  const {toDefault} = useRedirect();
  return (
    <Panel size="medium" status={status}>
      <Title
        titleTranslationKey={title}
        onCloseClick={toDefault}
        isPreviousPageLinkVisible
        onPreviousPageLinkClick={toDefault}
      />
      {children}
    </Panel>
  );
}

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    borderTop: `1px solid ${grey12}`,
    paddingTop: theme.spacing(2.5),
    paddingRight: theme.spacing(2.25),
    paddingLeft: theme.spacing(2.25),
    height: `calc(100vh - ${theme.spacing(9.75)}px - ${theme.spacing(
      8.75,
    )}px - ${theme.spacing(15)}px)`,
    overflowY: 'auto',
    ...scrollBarStyle,
  },
}));
interface IItemContentLayout {
  children: React.ReactNode;
}
export function ItemContentLayout(props: IItemContentLayout) {
  const classes = useStyles();
  return <div className={classes.container}>{props.children}</div>;
}
