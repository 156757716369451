import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import {Box, makeStyles, Theme} from '@material-ui/core';
import {QueryStatus} from 'react-query';
import {LayoutStatus} from '../PageLayout';

interface IPanel {
  children: React.ReactNode;
  size: 'small' | 'medium' | 'large' | 'full';
  status?: QueryStatus;
  onErrorRender?: () => React.ReactNode;
}
const useStyles = makeStyles<Theme, IPanel>(theme => ({
  content: {
    position: 'relative',
    height: '100%',
  },
  paper: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: props => {
        switch (props.size) {
          case 'full':
            return '100%';
          case 'large':
            return '1200px';
          case 'medium':
            return '800px';
          case 'small':
            return '400px';
        }
      },
    },
    maxWidth: '100vw',
    height: 'calc(100vh - 70px)',
    top: '70px',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
}));
export function Panel(props: IPanel) {
  const {children, status, onErrorRender} = props;
  const classes = useStyles(props);

  return (
    <Drawer open anchor="right" classes={{paper: classes.paper}}>
      <Box position="relative">
        {status ? (
          <LayoutStatus status={status} onErrorRender={onErrorRender}>
            <div className={classes.content}>{children}</div>
          </LayoutStatus>
        ) : (
          <div className={classes.content}>{children}</div>
        )}
      </Box>
    </Drawer>
  );
}
Panel.defaultProps = {
  size: 'small',
};
